import React from 'react';
import PropTypes from 'prop-types';

const ClickableDiv = ({ anyKey, children, className, id, onClick }) => {
  const handleKeyDown = anyKey
    ? onClick
    : (e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          onClick(e);
        }
      };
  return (
    <div
      aria-pressed="false"
      {...{ className, id }}
      role="button"
      tabIndex={0}
      onClick={onClick}
      onKeyDown={handleKeyDown}
    >
      {children}
    </div>
  );
};

ClickableDiv.propTypes = {
  anyKey: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

ClickableDiv.defaultProps = {
  anyKey: false,
};

export default ClickableDiv;
