import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { Link } from 'gatsby';

import { REGION } from '../../config';

import { loginWithLoginToken } from '../lib/api';
import { setLoginToken } from '../lib/storage';

import { Base, Jumbo } from '../components/common';
import SEO from '../components/seo';

import tr from '../../translations';

import './login.css';
import lylliIcon from '../images/lylli-icon.png';
import showPasswordIcon from '../images/show-icon.png';
import hidePasswordIcon from '../images/hide-icon.png';
import ClickableDiv from '../components/common/ClickableDiv';

const LOGIN_THROUGH_ROUTER = true;

class LoginPage extends React.Component {
  state = {
    showPassword: false,
    error: null,
    email: '',
    password: '',
  };

  handleSubmit = async (e) => {
    const { email, password } = this.state;

    e.preventDefault();
    e.stopPropagation();

    loginWithLoginToken(email, password)
      .then(({ _id: loginToken }) => {
        setLoginToken(loginToken);

        const loc = window.location;
        if (LOGIN_THROUGH_ROUTER) {
          if (loc.host.includes('localhost') || loc.host.includes('account-test.lylli.')) {
            loc.href = `https://lylli.webflow.io/router?lt=${loginToken}&region=${REGION}&origin=LOGIN`;
          } else {
            loc.href = `https://www.lylli.se/router?lt=${loginToken}&region=${REGION}&origin=LOGIN`;
          }
        } else {
          loc.href = '/profile';
        }
      })
      .catch((ex) => {
        const error = ex.statusCode === 401 ? tr('login.submit-error') : ex.message;
        this.setState({ error });
      });

    return false;
  };

  handleEmailChanged = ({ target: { value } }) => {
    this.setState({ email: value });
  };

  handlePasswordChanged = ({ target: { value } }) => {
    this.setState({ password: value });
  };

  render() {
    const { error, email, password } = this.state;

    return (
      <Base>
        <SEO title={tr('head.title')} />
        <Jumbo className="login" bgColor="#d1b7e4">
          <Row>
            <Col>
              <div className="login-icon-wrapper">
                <img className="login-icon" src={lylliIcon} alt="" />
              </div>
              <h1>{tr('login.title')}</h1>
              <Form onSubmit={this.handleSubmit} className="login-form">
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>{tr('login.email-label')}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={tr('login.email-placeholder')}
                    value={email}
                    onChange={this.handleEmailChanged}
                  />
                </Form.Group>

                <Form.Group controlId="formBasicPassword">
                  <Form.Label>{tr('login.password-label')}</Form.Label>
                  <Form.Control
                    type={this.state.showPassword ? 'text' : 'password'}
                    placeholder={tr('login.password-placeholder')}
                    isInvalid={!!error}
                    value={password}
                    onChange={this.handlePasswordChanged}
                  />
                  <div
                    className="show-hide-password-container"
                    onClick={this.toggleShowPassword}
                    aria-hidden="true"
                  >
                    <ClickableDiv onClick={() => this.setState({ showPassword: false })}>
                      <img
                        id="login-showPassword"
                        src={showPasswordIcon}
                        alt="visa"
                        style={this.state.showPassword ? { display: 'block' } : { display: 'none' }}
                      />
                    </ClickableDiv>
                    <ClickableDiv onClick={() => this.setState({ showPassword: true })}>
                      <img
                        id="login-hidePassword"
                        src={hidePasswordIcon}
                        alt="dölj"
                        style={this.state.showPassword ? { display: 'none' } : { display: 'block' }}
                      />
                    </ClickableDiv>
                  </div>
                  <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
                </Form.Group>

                <button className="login-submit-btn" type="submit">
                  {tr('login.submit')}
                </button>

                <Row className="text-center">
                  <Col className="login-link">
                    <a href={tr('link.createAccount')}>{tr('login.createAccount')}</a>
                  </Col>
                  <Col className="login-link">
                    <Link to="/reset-password">{tr('login.resetPassword')}</Link>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Jumbo>
      </Base>
    );
  }
}

export default LoginPage;
